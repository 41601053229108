@import "../../../styles/base/abstracts";

.hbs-search-box__dropdown {
  position: relative;
}

.hbs-search-box__dropdown-content {
  @include theme-white;
  @include padding($spacing-lg);
  position: absolute;
  background-color: $c-bg;
  border-top: $border-light;
  border-bottom-left-radius: $border-radius;
  border-bottom-right-radius: $border-radius;
  color: $c-text;
  width: 100%;
  z-index: $z-index-1;

  .hbs-search-box__dropdown--overlay & {
    position: static;
  }

  @include mq($bp-tablet) {
    @include padding($spacing-xl);
  }

  mark {
    color: $c-spot;
    font-weight: initial;
  }
}

.hbs-search-box__dropdown-results-label,
.hbs-search-box__dropdown-category,
.hbs-search-box__dropdown-contentType {
  color: $c-text-lighter;
}

.hbs-search-box__dropdown-results-label {
  @include small-text;
  padding-bottom: $spacing-sm;

  @include mq($bp-tablet) {
    margin-top: -$spacing-xs;
  }
}

.hbs-search-box__dropdown-content.suggestions {
  padding-top: $spacing-md;

  .hbs-search-box__dropdown-results-container {
    max-height: 15em;
    white-space: nowrap;
    overflow-y: auto;
    padding-top: 5px;
  }
}

ul.hbs-search-box__dropdown-results.group {
  padding-bottom: $spacing-sm;

  @include mq($bp-tablet) {
    margin-top: -$spacing-sm;
  }

  li:first-child {
    margin-top: $spacing-xs;
  }
}

ul.hbs-search-box__dropdown-results.group:before {
  @include small-text;
  content: attr(aria-label);
  margin-bottom: $spacing-md;
  color: $c-teal1;
  font-weight: $fw-semibold;
}

.hbs-search-box__dropdown-results {
  margin-bottom: $spacing-md;
}

.hbs-search-box__dropdown-result {
  padding-bottom: $spacing-xs;

  @include mq($bp-tablet) {
    padding-bottom: $spacing-xxs;
  }
}

.hbs-search-box__dropdown-result-link {
  text-decoration: none;
  display: flex;
  justify-content: space-between;

  &[aria-selected="true"] {
    outline: $outline;
  }
}

.hbs-search-box__dropdown-result-text {
  @include small-text;
  flex: 1;
  margin-right: $spacing-xs;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  span {
    color: $c-spot;
  }

  @include mq($bp-tablet) {
    @include baseline-text;
  }
}

.hbs-search-box__dropdown-category,
.hbs-search-box__dropdown-contentType {
  @include small-text;

  @include mq($bp-tablet) {
    @include baseline-text;
  }
}

.hbs-search-box__dropdown-footer {
  display: flex;
  align-items: center;

  & .hbs-cta-link[aria-selected="true"] {
    outline: $outline;
    outline-offset: 4px;
  }
}

.hbs-search-box__dropdown-footer-label {
  @include small-text;
  color: $c-text-lighter;
  flex: 1;
  margin-left: $spacing-md;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.hbs-search-box__overlay-trigger {
  @include reset-input;
  @include padding($spacing-xs);
  border-radius: $border-radius;
  fill: $c-theme-solid;
  margin-left: $spacing-xxs;
  flex-shrink: 0;

  @include mq-reduced-motion {
    transition: $transition;
  }

  @include hover {
    background-color: $c-theme-solid-inverse;
    fill: $c-spot;
  }

  svg {
    @include size($icon-size);
  }
}

@keyframes search-overlay-fade-in {
  from {
    background-color: transparent;
  }

  to {
    background-color: transparentize($c-black, 0.15);
  }
}

@keyframes search-overlay-fade-out {
  from {
    background-color: transparentize($c-black, 0.15);
  }

  to {
    background-color: transparent;
  }
}

@keyframes opacity-fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.hbs-search-box__overlay {
  @include fixed(0);
  backdrop-filter: blur(0px);
  z-index: $z-index-9;
  transition: $t-time-xs $t-ease-out;

  &[data-state="open"] {
    animation: search-overlay-fade-in $t-time-sm $t-ease-out forwards;
    backdrop-filter: blur(10px);
    transition: $transition-md;
  }

  &[data-state="closed"] {
    animation: search-overlay-fade-out $t-time-xs $t-ease-out;
  }
}

.hbs-search-box__overlay-content {
  @include fixed(20px x x 50%);
  transform: translate(-50%, 0);
  max-width: 700px;
  width: calc(100% - 40px);

  @include mq($bp-tablet) {
    top: 30%;
  }

  &[data-state="open"] {
    animation: opacity-fade-in $t-time-sm $t-ease-out forwards;
  }

  &[data-state="closed"] {
    animation: fadeOut $t-time-xs $t-ease-out;
  }
}

.hbs-search-box__overlay-close {
  @include small-text;
  @include subtle-link;
  @include theme-dark;
  color: $c-text;
  text-align: center;
  margin-top: $spacing-md;
  width: 100%;
}
